<template>
	<v-container fluid>
		<v-row dense>
			<!-- cols 12, sm 6, md 4, lg 2 -->
			<v-col
				v-bind="smallEl.colDef"
				v-for="(smallEl, i) of smallsDef"
				:key="i">
				<v-card
					style="height: 100%"
					class="d-flex align-center my-0 mx-1">
					<div
						class="d-flex align-center"
						style="width: 100%">
						<v-col class="mx-2 d-flex justify-center px-0">
							<v-progress-circular
								:color="
									(smallEl.value / smallEl.compareVal) * 100 - 100 > 0
										? 'success'
										: 'error'
								"
								:value="
									Math.abs((smallEl.value / smallEl.compareVal) * 100 - 100)
								"></v-progress-circular>
						</v-col>
						<v-col
							cols="9"
							class="mx-2 align-center px-0">
							<div class="d-block text-truncate">{{ smallEl.title }}</div>
							<div class="d-flex align-center">
								<span class="text-h5">
									{{ smallEl.prefix }}
									{{
										smallEl.filter
											? smallEl.filter(smallEl.value)
											: smallEl.value
									}}{{ smallEl.postfix }}
								</span>
								<span
									class="ml-2 text-caption d-flex align-center"
									:class="`${
										(smallEl.value / smallEl.compareVal) * 100 - 100 > 0
											? 'success'
											: 'error'
									}--text`">
									{{
										Math.round(
											(smallEl.value / smallEl.compareVal) * 100 - 100
										)
									}}%
								</span>
							</div>
						</v-col>
					</div>
				</v-card>
			</v-col>
		</v-row>
		<v-row
			dense
			class="pt-2 mb-2">
			<v-col
				cols="12"
				sm="6"
				lg="3"
				v-for="(midEl, i) of midDefs"
				:key="i">
				<v-card class="mt-0 mx-1 fill-height">
					<v-card-title>{{ midEl.title }}</v-card-title>
					<div class="mx-4 mt-7 mb-4 d-flex flex-row align-center">
						<v-progress-linear
							class="px-4"
							rounded
							height="4"
							:color="
								(midEl.value / midEl.compareVal) * 100 - 100 > 0
									? 'success'
									: 'error'
							"
							:value="
								Math.abs(
									Math.round((midEl.value / midEl.compareVal) * 100 - 100)
								)
							"></v-progress-linear>
					</div>
					<v-card-text class="py-0 d-flex align-center">
						<span class="text-h4">{{ midEl.value }}</span>
						<span
							class="text-h7 ml-2"
							:class="
								(midEl.value / midEl.compareVal) * 100 - 100 > 0
									? 'success--text'
									: 'error--text'
							">
							{{ Math.round((midEl.value / midEl.compareVal) * 100) }}%
						</span>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<!-- <v-row dense class="pt-2">
			<v-col cols="3" v-for="(midEl, i) of midDefs" :key="i"
				><v-card class="mt-0 fill-height">
					<v-card-title>{{ midEl.title }}</v-card-title>
					<div class="mx-2 d-flex flex-row justify-center align-center">
						<div style="height: 10vh; width: 90%">
							<Component
								:data="chartMiniData"
								:options="chartMiniOptions"
								:is="'chart-line'"></Component>
						</div>
					</div>
					<v-card-text class="py-0 d-flex align-center"
						><span class="text-h4">{{ midEl.value }}</span
						><span
							class="text-h7 ml-2"
							:class="
								(midEl.value / midEl.compareVal) * 100 - 100 > 0
									? 'success--text'
									: 'error--text'
							"
							>{{ Math.round((midEl.value / midEl.compareVal) * 100) }}%</span
						><span class="text-h4 ml-3">{{ midEl.value }}</span
						><span
							class="text-h7 ml-2"
							:class="
								(midEl.value / midEl.compareVal) * 100 - 100 > 0
									? 'success--text'
									: 'error--text'
							"
							>{{ Math.round((midEl.value / midEl.compareVal) * 100) }}%</span
						></v-card-text
					>
				</v-card></v-col
			>
		</v-row> -->
		<v-row dense>
			<v-col
				cols="12"
				md="6"
				lg="4">
				<v-card class="mx-1 px-4 py-2 mt-0 fill-height">
					<v-card-title>Bets</v-card-title>
					<div style="height: 83%">
						<Component
							:data="chartData"
							:options="chartOptions"
							:is="'chart-bar'"></Component>
					</div>
				</v-card>
			</v-col>
			<v-col
				cols="12"
				md="6"
				lg="4">
				<v-card class="mx-1 px-4 py-0 mt-0 fill-height">
					<v-card-title>Netto</v-card-title>
					<div style="height: 83%">
						<Component
							:data="chartData"
							:options="chartOptions"
							:is="'chart-line'"></Component>
					</div>
				</v-card>
			</v-col>
			<v-col
				cols="12"
				md="12"
				lg="4">
				<v-card class="mx-1 px-4 py-0 mt-0 fill-height d-flex flex-column">
					<v-card-title class="align-center d-flex flex-row">
						<v-switch
							v-model="pieDataPart"
							class="mt-0"
							hide-details></v-switch>
						<div class="ml-2 mt-1">
							{{ pieDataPart ? "Screens" : "Devices" }}
						</div>
					</v-card-title>

					<div
						class="d-flex align-center"
						style="height: 83%">
						<v-col cols="8">
							<Component
								:data="pieData"
								:options="pieOptions"
								ref="piePie"
								:is="'chart-pie'"></Component>
						</v-col>
						<v-col>
							<div class="d-flex flex-column">
								<span
									style="user-select: none"
									@mouseenter="triggerHover(i)"
									@mouseleave="triggerHover(-1)"
									class="mb-1"
									v-for="(label, i) of this.pieDataPart
										? pieLabels2
										: pieLabels"
									:key="i">
									<v-icon
										:style="`color: ${getColor(i)} !important`"
										class="mr-2">
										{{ label.icon }}
									</v-icon>
									<span>{{ label.name }}</span>
								</span>
							</div>
						</v-col>
					</div>
				</v-card>
			</v-col>
		</v-row>
		<v-row class="mt-1">
			<v-col
				cols="12"
				lg="8">
				<v-card class="mt-0 fill-height">
					<v-card-title>Sessions</v-card-title>
					<v-data-table
						dense
						:items="tableItems"
						:headers="tableHeaders"
						hide-default-footer>
						<template #[`item.sum_bet`]="{ item }">
							{{ item.sum_bet | Number({ currency: item.currency }) }}
						</template>
						<template #[`item.sum_win`]="{ item }">
							{{ item.sum_bet | Number({ currency: item.currency }) }}
						</template>
						<template #[`item.netto`]="{ item }">
							{{ item.sum_bet | Number({ currency: item.currency }) }}
						</template>
					</v-data-table>
				</v-card>
			</v-col>
			<v-col
				cols="12"
				lg="4">
				<v-card class="mt-0 fill-height">
					<v-card-title>Rounds</v-card-title>
					<v-data-table
						dense
						:items="tableItems2"
						:headers="tableHeaders2"
						hide-default-footer></v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// import searchVue from "../components/shared/LoganSearch.vue";
import permissions from "../mixins/permissions";
import { rules as defaultRules } from "../plugins/rules.ts";
import * as chartutils from "../constants/chartutils";

import { mapActions, mapGetters, mapMutations } from "vuex";
import {
	Bar as chartBar,
	Line as chartLine,
	Doughnut as chartPie,
} from "vue-chartjs";
import { Chart as ChartJS } from "chart.js";

function colorize(opaque) {
	return (ctx) => {
		const v = ctx.parsed.y;
		const c =
			v > 0 ? chartutils.CHART_COLORS.blue : chartutils.CHART_COLORS.red;

		return opaque
			? chartutils.transparentize(c, 1)
			: chartutils.transparentize(c, 0.5);
	};
}

export default {
	components: {
		// searchVue,
		chartBar,
		chartLine,
		chartPie,
	},
	data() {
		return {
			pieDataPart: false,
			pieChart: undefined,
			pieLabels: [
				{ name: "Mobile", icon: "mdi-cellphone" },
				{ name: "Tablet", icon: "mdi-tablet" },
				{ name: "Desktop", icon: "mdi-monitor" },
				{ name: "Unknown", icon: "mdi-help-circle-outline" },
			],
			pieLabels2: [
				{ name: "Portrait", icon: "mdi-cellphone" },
				{ name: "Landscape", icon: "mdi-tablet" },
				{ name: "Unknown", icon: "mdi-help-circle-outline" },
			],
			smallsDef: [
				{
					title: "Avg Bet",
					compareVal: 100,
					value: 140,
					filter: (e) => {
						return this.$options.filters.Number(e, { currency: "EUR" });
					},
					colDef: { cols: 12, md: 4, lg: true },
				},
				{
					title: "Avg Win",
					compareVal: 100,
					value: 25,
					colDef: { cols: 12, md: 4, lg: true },
				},
				{
					title: "Rounds / Session",
					compareVal: 100,
					value: 166,
					colDef: { cols: 12, md: 4, lg: true },
				},
				{
					title: "New Players",
					compareVal: 20,
					value: 22,
					colDef: { cols: 12, md: 6, lg: true },
				},
				{
					title: "Free Spins",
					compareVal: 100,
					value: 16,
					colDef: { cols: 12, md: 6, lg: true },
				},
			],
			midDefs: [
				{ title: "RTP", compareVal: 100, value: 140 },
				{ title: "Sessions", compareVal: 100, value: 25 },
				{ title: "Rounds", compareVal: 100, value: 166 },
				{ title: "Bet", compareVal: 20, value: 22 },
			],
			tableHeaders: [
				{ text: "Game name", value: "game_name", align: "left", isData: false },
				{ text: "Σ Bet", value: "sum_bet", align: "right", isData: true },
				{ text: "Σ Win", value: "sum_win", align: "right", isData: true },
				{ text: "Netto", value: "netto", align: "right", isData: true },
				{
					text: "Player ID",
					value: "player_mongo_id",
					align: "right",
					isData: true,
				},
				{ text: "Rounds", value: "rounds_count", align: "right", isData: true },
			],
			tableHeaders2: [
				{ text: "Game name", value: "game_name", align: "left", isData: false },
				{ text: "Σ Bet", value: "bet", align: "right", isData: true },
				{ text: "Σ Win", value: "win", align: "right", isData: true },
				{ text: "Netto", value: "netto", align: "right", isData: true },
			],
			tableItems2: [
				{
					netto: 400,
					bet: 400,
					win: 0,
					game_name: "Simply Hot XL 160",
					currency: "CZK",
					minor_units: 2,
				},
				{
					netto: 800,
					bet: 800,
					win: 0,
					game_name: "Simply Hot XL 160",
					currency: "CZK",
					minor_units: 2,
				},
				{
					netto: 800,
					bet: 800,
					win: 0,
					game_name: "Simply Hot XL 160",
					currency: "CZK",
					minor_units: 2,
				},
				{
					netto: 800,
					bet: 800,
					win: 0,
					game_name: "Simply Hot XL 160",
					currency: "CZK",
					minor_units: 2,
				},
				{
					netto: 800,
					bet: 800,
					win: 0,
					game_name: "Simply Hot XL 160",
					currency: "CZK",
					minor_units: 2,
				},
				{
					netto: -800,
					bet: 800,
					win: 1600,
					game_name: "Simply Hot XL 160",
					currency: "CZK",
					minor_units: 2,
				},
				{
					netto: 800,
					bet: 800,
					win: 0,
					game_name: "Simply Hot XL 160",
					currency: "CZK",
					minor_units: 2,
				},
				{
					netto: 800,
					bet: 800,
					win: 0,
					game_name: "Simply Hot XL 160",
					currency: "CZK",

					minor_units: 2,
				},
				{
					netto: 800,
					bet: 800,
					win: 0,
					game_name: "Simply Hot XL 160",
					currency: "CZK",
					minor_units: 2,
				},
				{
					netto: 80,
					bet: 800,
					win: 720,
					game_name: "Simply Hot XL 160",
					currency: "CZK",
					minor_units: 2,
				},
			],
			tableItems: [
				{
					session_mongo_id: "64ec94292b5afaa277af57bb",
					player_mongo_id: "75",
					game_name: "Book of Moon",
					game_id: "book_of_moon",
					player: null,
					start_time: "2023-08-28T12:33:45.069406Z",
					end_time: "2023-08-28T12:34:18.694323Z",
					internal: "64ec94292b5afaa277af57bb",
					external: null,
					currency: "COP",
					sum_bet: 1600000,
					sum_win: 160000000,
					netto: -158400000,
					rounds_count: 2,
					external_ticket_id: null,
				},
				{
					session_mongo_id: "64e8bb13efefe8e1e14b03f8",
					player_mongo_id: "75",
					game_name: "Book of Moon",
					game_id: "book_of_moon",
					player: null,
					start_time: "2023-08-25T14:30:42.641765Z",
					end_time: "2023-08-25T14:31:41.834107Z",
					internal: "64e8bb13efefe8e1e14b03f8",
					external: null,
					currency: "COP",
					sum_bet: 1600000,
					sum_win: 160000000,
					netto: -158400000,
					rounds_count: 2,
					external_ticket_id: null,
				},
				{
					session_mongo_id: "64ec39d19661d4b8ab033f26",
					player_mongo_id: "75",
					game_name: "Book of Moon",
					game_id: "book_of_moon",
					player: null,
					start_time: "2023-08-28T06:08:17.214250Z",
					end_time: "2023-08-28T06:20:50.965397Z",
					internal: "64ec39d19661d4b8ab033f26",
					external: null,
					currency: "COP",
					sum_bet: 1600000,
					sum_win: 160000000,
					netto: -158400000,
					rounds_count: 2,
					external_ticket_id: null,
				},
				{
					session_mongo_id: "641c4aaf1a4e6f66a2fe3fd9",
					player_mongo_id: "153",
					game_name: "Happy Diamonds",
					game_id: "happy_diamonds",
					player: null,
					start_time: "2023-03-23T12:49:01.256714Z",
					end_time: "2023-03-23T12:52:32.126987Z",
					internal: null,
					external: null,
					currency: "CZK",
					sum_bet: 2300000,
					sum_win: 159400000,
					netto: -157100000,
					rounds_count: 23,
					external_ticket_id: null,
				},
				{
					session_mongo_id: "6421399c97046e7d60ecc897",
					player_mongo_id: "122",
					game_name: "Happy Diamonds",
					game_id: "happy_diamonds",
					player: null,
					start_time: "2023-03-27T06:37:48.592283Z",
					end_time: "2023-03-27T07:02:52.819023Z",
					internal: null,
					external: null,
					currency: "CZK",
					sum_bet: 4800000,
					sum_win: 159000000,
					netto: -154200000,
					rounds_count: 48,
					external_ticket_id: null,
				},
				{
					session_mongo_id: "6437d46bd1d6c5d3c196a1ee",
					player_mongo_id: "153",
					game_name: "Tutti Frutti",
					game_id: "tutti_frutti",
					player: null,
					start_time: "2023-04-13T10:07:39.209001Z",
					end_time: "2023-04-13T10:15:24.432845Z",
					internal: "6437d46bd1d6c5d3c196a1ee",
					external: null,
					currency: "CZK",
					sum_bet: 3700000,
					sum_win: 159000000,
					netto: -155300000,
					rounds_count: 37,
					external_ticket_id: null,
				},
				{
					session_mongo_id: "64b529ed06bc5bb9dbffa1ec",
					player_mongo_id: "76",
					game_name: "Multi Vegas 81 Exclusive",
					game_id: "multi_vegas_81_exclusive",
					player: null,
					start_time: "2023-07-17T11:45:48.842545Z",
					end_time: "2023-07-17T11:46:14.750010Z",
					internal: "64b529ed06bc5bb9dbffa1ec",
					external: null,
					currency: "COP",
					sum_bet: 3000000,
					sum_win: 159000000,
					netto: -156000000,
					rounds_count: 1,
					external_ticket_id: null,
				},
				{
					session_mongo_id: "641c3b44e29ae236508c10db",
					player_mongo_id: "153",
					game_name: "Happy Diamonds",
					game_id: "happy_diamonds",
					player: null,
					start_time: "2023-03-23T11:43:09.781647Z",
					end_time: "2023-03-23T11:48:49.003715Z",
					internal: null,
					external: null,
					currency: "CZK",
					sum_bet: 2108000,
					sum_win: 158600000,
					netto: -156492000,
					rounds_count: 22,
					external_ticket_id: null,
				},
				{
					session_mongo_id: "6447ce1a973378197f1ca0bf",
					player_mongo_id: "153",
					game_name: "Sirens Calling",
					game_id: "sirens_calling",
					player: null,
					start_time: "2023-04-25T12:56:58.273009Z",
					end_time: "2023-04-25T13:36:36.692883Z",
					internal: "6447ce1a973378197f1ca0bf",
					external: null,
					currency: "CZK",
					sum_bet: 27450900,
					sum_win: 158527750,
					netto: -131076850,
					rounds_count: 288,
					external_ticket_id: null,
				},
				{
					session_mongo_id: "64241bce62e9c72063a327ad",
					player_mongo_id: "153",
					game_name: "Happy Diamonds",
					game_id: "happy_diamonds",
					player: null,
					start_time: "2023-03-29T11:06:54.301532Z",
					end_time: "2023-03-29T11:11:56.951399Z",
					internal: "64241bce62e9c72063a327ad",
					external: null,
					currency: "CZK",
					sum_bet: 2100000,
					sum_win: 158500000,
					netto: -156400000,
					rounds_count: 21,
					external_ticket_id: null,
				},
			],
			chartMiniData: {
				labels: chartutils.months({ count: 12 }),

				datasets: [
					{
						pointStyle: false,
						data: chartutils.numbers({ count: 12, min: -15, max: 100 }),
						backgroundColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan,
							0.5
						),
						borderColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan
						),
						borderWidth: 2,
						borderRadius: Number.MAX_VALUE,
						borderSkipped: false,
					},
				],
			},

			chartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						ticks: {
							display: true,
						},
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
					},
					y: {
						ticks: {
							display: false,
						},
						border: {
							display: false,
						},
						grid: {
							display: false,
						},
					},
				},

				elements: {
					point: {
						pointStyle: "circle",
						borderColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan,
							1
						),
					},
					bar: {
						backgroundColor: colorize(false),
						borderColor: colorize(true),
						borderWidth: 2,
					},
					line: {
						tension: 0.4,
						borderWidth: 2,
						borderColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan,
							1
						),
						backgroundColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan,
							1
						),
						fill: {
							target: "origin",
							above: chartutils.transparentize(
								chartutils.CHART_COLORS.logan,
								0.2
							), // Area will be red above the origin
							below: chartutils.transparentize(
								chartutils.CHART_COLORS.red,
								0.5
							), // And blue below the origin
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					title: {
						font: {
							weigth: 500,
							size: 18,
							family: "'Roboto', sans-serif",
						},
						display: false,
						align: "start",
						color: "white",
						text: "Chart.js Bar Chart",
					},
				},
			},
			chartMiniOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						ticks: {
							display: false,
						},
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
					},
					y: {
						ticks: {
							display: false,
						},
						border: {
							display: false,
						},
						grid: {
							display: false,
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					title: {
						font: {
							weigth: 500,
							size: 18,
							family: "'Roboto', sans-serif",
						},
						display: false,
						align: "start",
						color: "white",
						text: "Chart.js Bar Chart",
					},
					colors: {
						forceOverride: true,
					},
				},
			},
			pieOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						ticks: {
							display: false,
						},
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
					},
					y: {
						ticks: {
							display: false,
						},
						border: {
							display: false,
						},
						grid: {
							display: false,
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					title: {
						font: {
							weigth: 500,
							size: 18,
							family: "'Roboto', sans-serif",
						},
						display: false,
						align: "start",
						color: "white",
						text: "Chart.js Bar Chart",
					},
					colors: {
						forceOverride: true,
					},
				},
			},
			enabledGraphs: ["chart-bar", "chart-line", "chart-pie"],
			sessionTimerange: undefined,
			gameTimerange: undefined,
			moniTimerange: undefined,
			fullText: undefined,
			roundMenu: false,
			errorMessages: {
				session: [],
				player: [],
				round: [],
			},
			rules: {
				...defaultRules,
			},
			loadedData: [],
			dialog: false,
			navigationFn: () => {},
			headers: [],
			importHeaders: [],
			importData: [],
			roundSearchHeaders: [
				{ text: "Round ID", value: "round_id", align: "center" },
				{ text: "Start Time", value: "start_time", align: "center" },
				{
					text: "External Round",
					value: "external_round",
					align: "center",
					hide: () => this.loadedData.every((el) => el.external === null),
				},
				{ text: "External Bet", value: "external_bet", align: "center" },
				{ text: "External Win", value: "external_win", align: "center" },
				{ text: "Bet", value: "bet", align: "right" },
				{ text: "Win", value: "win", align: "right" },
				{ text: "Netto", value: "netto", align: "right" },
				{
					text: "Orientation",
					value: "orientation",
					align: "center",
					hide: () => this.loadedData.every((el) => el._device === "Desktop"),
				},
				{
					text: "Finished",
					value: "finished",
					align: "center",
					sortable: false,
				},
			],
			sessionSearchHeaders: [
				{ text: "Session ID", value: "session_mongo_id", align: "center" },
				{
					text: "External",
					value: "external",
					align: "center",
					hide: () => this.loadedData.every((el) => !el?.external),
				},
				{
					text: "External ticket",
					value: "external_ticket",
					align: "center",
					hide: () => this.loadedData.every((el) => !el?.external),
				},
				{ text: "Game", value: "game_name", align: "center" },
				{ text: "Player", value: "player_mongo_id", align: "center" },
				{ text: "Start Time", value: "start_time", align: "center" },
				{ text: "End Time", value: "end_time", align: "center" },
				{ text: "Σ Bet", value: "sum_bet", align: "right" },
				{ text: "Σ Win", value: "sum_win", align: "right" },
				{ text: "Netto", value: "netto", align: "right" },
				{ text: "Finished", value: "finished", align: "center" },
				{ text: "Round Count", value: "rounds_count", align: "center" },
			],
			playerSearchHeaders: [
				{ text: "Player ID", value: "player_mongo_id", align: "center" },
				{ text: "Nick", value: "nick", align: "center" },
				{ text: "Player's Balance", value: "balance", align: "right" },
				{ text: "Σ Bet", value: "sum_bet", align: "right" },
				{ text: "Σ Win", value: "sum_win", align: "right" },
				{ text: "Session Count", value: "sessions_count", align: "center" },
				{ text: "Round Count", value: "games_count", align: "center" },
			],
		};
	},
	computed: {
		...mapGetters(["allowedSections"]),
		...mapGetters(["roundSearch", "sessionSearch", "playerSearch"]),
		...mapGetters("sessions2", ["activeSessions", "sessionsPer", "mostPlayed"]),
		hasPreviousSearch() {
			return !!sessionStorage.getItem("roundSearchKeys");
		},
		chartData() {
			return {
				labels: chartutils.months({ count: 12 }),
				datasets: [
					{
						data: chartutils.numbers({ count: 12, min: -15, max: 100 }),
						borderRadius: Number.MAX_VALUE,
						borderSkipped: false,
					},
				],
			};
		},
		pieData() {
			return {
				labels: this.pieDataPart
					? this.pieLabels2.map((el) => el.name)
					: this.pieLabels.map((el) => el.name),
				datasets: [
					{
						hoverOffset: 20,
						borderColor: this.$vuetify.theme.dark ? "#1e1e1eff" : "#ffffffff",
						borderWidth: "4",
						hidden: this.pieDataPart,
						data: chartutils.numbers({
							count: 4,
							min: -100,
							max: 100,
						}),
					},
					{
						borderColor: this.$vuetify.theme.dark ? "#1e1e1eff" : "#ffffffff",
						borderWidth: "4",
						hidden: !this.pieDataPart,
						data: chartutils.numbers({
							count: 3,
							min: -100,
							max: 100,
						}),
					},
				],
			};
		},
		canViewSessions() {
			///TODO: add correct permission check
			return true;
			// return this.allowedSections.logan
			// 	.map((section) => section.section_name)
			// 	.includes("Session");
		},
		canViewPlayers() {
			///TODO: add correct permission check
			return true;
			// return this.allowedSections.logan
			// 	.map((section) => section.section_name)
			// 	.includes("Player");
		},
		filteredHeaders() {
			return this.headers.filter((el) => !el.hide?.());
		},
	},
	mixins: [permissions],
	methods: {
		triggerHover(i) {
			const ds = this.pieDataPart ? 1 : 0;
			const tooltip = this.pieChart.tooltip;
			if (i === -1) {
				this.pieChart.setActiveElements([]);
				tooltip.setActiveElements([]);
			} else {
				this.pieChart.setActiveElements([
					{
						datasetIndex: ds,
						index: i,
					},
				]);
				tooltip.setActiveElements([
					{
						datasetIndex: ds,
						index: i,
					},
				]);
			}
			this.pieChart.update();
		},
		getColor(index) {
			try {
				return this.pieChart?.data?.datasets?.[this.pieDataPart ? 1 : 0]
					?.backgroundColor?.[index];
			} catch (error) {
				console.error(error);
			}
		},
		...mapMutations("bulkSearch", ["setOpen"]),
		...mapMutations("sessions2", {
			sessionPagination: "pagination",
		}),
		bulkSearchClick() {
			if (this.hasPreviousSearch) return;
			this.setOpen(true);
		},
		returnToRounds() {
			this.$router.push({
				name: "rounds",
				params: {
					breadcrumbs: { title: `Rounds`, name: "rounds", hard: true },
				},
			});
		},
		...mapActions([
			"loadRoundSearch",
			"loadBulkRoundSearch",
			"loadSessionSearch",
			"loadPlayerSearch",
		]),
		clearError(e) {
			this.$set(this.errorMessages, e, []);
		},
		mapHeaders(headers) {
			return (
				headers?.map((el) => {
					return {
						text: el,
						value: el,
						sortable: false,
						align: "center",
					};
				}) ?? []
			);
		},
		async onSubmit(val, searchFn, searchGetter, tableHeaders, routingFn, type) {
			await searchFn({ searchKey: val, noErr: true });

			if (!searchGetter.data) {
				this.$set(this.errorMessages, type, [`Specified ${type} not found.`]);
				return;
			}

			if (searchGetter.data.length === 1) {
				routingFn(searchGetter.data[0]);
				return;
			}

			this.navigationFn = routingFn;
			this.loadedData = searchGetter.data;
			this.headers = tableHeaders;
			this.dialog = true;
		},
		roundRouting(round) {
			this.$router.push({
				name: "session-detail",
				params: { session_id: round.session_mongo_id },
				query: {
					page: round.page_number,
					highlightId: round.round_id,
				},
			});
		},
		sessionRouting(session) {
			this.$router.push({
				name: "session-detail",
				params: { session_id: session.session_mongo_id },
			});
		},
		playerRouting(player) {
			this.$router.push({
				name: "player-detail",
				params: { player_id: player.player_mongo_id },
			});
		},
	},
	async mounted() {
		this.pieChart = ChartJS.getChart(this.$refs.piePie.$el);
	},
	async created() {},
};
</script>
<style scoped>
::v-deep.v-menu__content {
	box-shadow: none !important;
}
.atop {
	position: relative;
}
.atop:after {
	content: " ";
	position: absolute;
	right: 50%;
	top: -15px;
	transform: translate(50%, 0);
	border-top: none;
	border-right: 15px solid transparent;
	border-left: 15px solid transparent;
	border-bottom: 15px solid var(--v-primary-darken2);
}
.search-header {
	cursor: pointer;
	border-radius: 10px;
	border: 4px solid transparent;
	background-color: var(--v-menu_background-base);
	background-clip: padding-box;
	padding: 6px 6px;
	position: relative;
	text-transform: uppercase;
}

.search-header:hover {
	background-color: var(--v-primary-base);
}
::v-deep .v-badge__badge::after {
	border-color: var(--v-secondary-base) !important;
}

.gridcontainer {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	grid-template-rows: repeat(auto-fill, 21px);
	grid-auto-rows: 21px;
	column-gap: 1rem;
	margin-left: 1rem;
	margin-right: 1rem;
}
</style>
