import { isNullish } from "../constants/helpers";

export const rules = {
	required: (v) => {
		if (v === null || v === undefined || v.toString().length < 1) {
			return "This field is required";
		}
		return true;
	},
	decimal: (v) =>
		/^\d+(\.\d{1,6})?$/.test(v) ||
		"Must be a number, with max 6 decimal places",
	nonNegative: (v) => parseFloat(v) >= 0 || "Must be a non negative number",
	positiveInteger: (v) => {
		if (!v || v == null || v == undefined) return true;
		if (!/^[0-9][0-9]*$/.test(v)) {
			return "Must be a non negative whole number";
		} else {
			return true;
		}
	},
	positiveNumber: (v) => {
		if (v == null || v == undefined) return true;
		return (
			parseFloat(v) > 0 || "Must be a non negative number greater then zero"
		);
	},

	percentage: (v) =>
		(v >= 0 && v <= 100) || "Must be a number between 0 and 100",
	no_spaces: (v) =>
		(v && !v.includes(" ")) || "This field can't contain spaces",
	minMaxLength(min, max = null) {
		return (v) => {
			if (v === undefined || v === null) {
				return "Value has to be defined";
			}

			if (v.toString().length < min) {
				return `Min length of ${min} characters`;
			}

			if (max !== null && v.toString().length > max) {
				return `Max length of ${max} characters`;
			}

			return true;
		};
	},
	minMaxCharacters: (value) =>
		(value && value.length >= 3 && value.length <= 10) ||
		"Min 3 characters and Max 10 characters",
	minMaxNameLength: (value) =>
		(value && value.length >= 3 && value.length <= 42) ||
		"Min 3 characters and Max 42 characters",
	minMaxValueLength: (value) =>
		(value &&
			(typeof value == "string" || typeof value == "number") &&
			value.toString() &&
			value.toString().length > 0 &&
			value.toString().length <= 10) ||
		"Min 1 character and Max 10 characters",
	mindfuck: (v) =>
		/^((100(\.0{1,2})?)|(\d{1,2}(\.\d{1,2})?))$/.test(v) ||
		"Not a valid percentage.", // Martin Fric (c)
	maxValue100: (v) => v < 100 || "Max value: 100",
	/**
	 *
	 *
	 * @param {Number} min
	 * @param {Number} [max=null]
	 * @param {{inclusiveLeft: Boolean, inclusiveRight: Boolean}} optionsOverride
	 * @return {String | Boolean}
	 */
	minMaxValue(min, max = null, optionsOverride) {
		const options = {
			inclusiveLeft: true,
			inclusiveRight: true,
			...optionsOverride,
		};
		const minPredicate = options.inclusiveLeft
			? (a, b) => a < b
			: (a, b) => a <= b;
		const maxPredicate = options.inclusiveRight
			? (a, b) => a > b
			: (a, b) => a >= b;
		return (v) => {
			if (minPredicate(v, min)) {
				return `Value must be greater than ${min}`;
			}

			if (!isNullish(max) && maxPredicate(v, max)) {
				return `Value must be less than ${max}`;
			}

			return true;
		};
	},
	type(type) {
		return (v) => {
			if (typeof v !== type) {
				return `Value must be of type ${type}`;
			}
			return true;
		};
	},
};
